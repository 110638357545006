import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { getCustomerPortalSession, getPlanInfo } from '../actions/subscriptionActions';
import FormContainer from '../components/FormContainer';
import _ from 'lodash';

// import { BASE_API_URL } from "../constants/commonConstants";
import Loader from '../components/Loader';

const AccountScreen = ({ history }) => {
	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;
	const [currentPlan, setCurrentPlan] = useState(null);
	const planInfoState = useSelector((state) => state.planState);
	const { planInfo } = planInfoState;
	const dispatch = useDispatch();
	const cancelState = useSelector((state) => state.cancelSubscriptionState);
	const { cancelledSub, loading: cancelSubLoading, error: cancelSubError } = cancelState;
	const manageSubscriptionState = useSelector((state) => state.customerPortalState);
	const { url } = manageSubscriptionState;

	useEffect(() => {
		if (url) {
			window.location.href = url;
		}
	}, [url, userInfo]);

	const handleManageSubscription = () => {
		dispatch(getCustomerPortalSession(userInfo));
	};

	useEffect(() => {
		if (!userInfo || !userInfo.email) {
			history.push('/login');
		} else {
			dispatch(getPlanInfo(userInfo));
		}
	}, [dispatch, history, userInfo]);

	useEffect(() => {
		if (planInfo && !_.isEmpty(planInfo)) {
			setCurrentPlan(planInfo);
		} else {
			setCurrentPlan(null);
		}
	}, [userInfo, planInfo]);

	return (
		<FormContainer>
			{cancelSubError && <Alert variant="danger">{cancelSubError}</Alert>}
			{cancelledSub && !currentPlan && (
				<Alert variant="info">
					Subscription has been cancelled. You have been reverted to free plan.
				</Alert>
			)}
			<h3 className="text-center">Account Settings</h3>
			<Card>
				<Card.Header>
					<h4>Account</h4>
				</Card.Header>
				<Card.Body>
					<Row>
						<Col>
							<Card.Title>{userInfo && userInfo.name}</Card.Title>
						</Col>
						<Col className="text-right">
							<Card.Title className="mb-2 text-muted">
								{userInfo && userInfo.email}
							</Card.Title>
						</Col>
					</Row>
					{currentPlan && (
						<Row className="text-muted">
							<Col>
								<h5>Credit card</h5>
							</Col>
							<Col>
								<h5 className="text-right text-capitalize">
									{currentPlan.cardBrand + ' •••• ' + currentPlan.lastFour}
								</h5>
							</Col>
						</Row>
					)}
					<Row>
						<Col>
							<h4>Current Plan</h4>
						</Col>
						<Col className="text-right">
							<h4>{currentPlan ? currentPlan.planNickName : 'Free Plan'}</h4>
						</Col>
					</Row>
					{currentPlan ? (
						<Alert variant="light">
							Open a new tab and make sure to login using your email above to
							enjoy your subscription.
						</Alert>
					) : (
						<Alert variant="light">Enjoy more features by upgrading.</Alert>
					)}
					{currentPlan ? (
						<Row>
							<Col className="text-center">
								<Button
									variant="primary"
									onClick={handleManageSubscription}
								>
									Manage Subscription
								</Button>
							</Col>
						</Row>
					) : (
						<LinkContainer to="/upgrade">
							<Button>Upgrade Now</Button>
						</LinkContainer>
					)}
				</Card.Body>
			</Card>
			<br/>
			{cancelSubLoading && <Loader/>}
		</FormContainer>
	);
};

export default AccountScreen;
