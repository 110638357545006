export const hiMessages = {
  app_name: {
    message: "Chrome Theme Infinity New Tab Backgrounds HD",
    description: "The title of the application,displayed in the web store",
  },
  app_short_name: {
    message: "UseDelight",
    description:
      "The short title of the application,displayed in the web store and from the app launcher",
  },
  app_description: {
    message:
      "मौसम, खोज इंजन, खेल के साथ मुफ्त वीडियो वॉलपेपर अविश्वसनीय शुरुआत पृष्ठ",
    description:
      "The description of the application,displayed in the web store",
  },
  new_tab: {
    message: "नया टैब",
    description: "Title of the New Tab page",
  },
  no_connection: {
    message: "कोई इंटरनेट कनेक्शन नहीं",
    description: "Alerts the user that they have no connection to the internet",
  },
  battery_mode: {
    message: "पारिस्थितिकी प्रणाली",
    description: "Alerts the user that they are seeing the battery mode",
  },
  loading_videos: {
    message: "वीडियो लोड हो रहे हैं ...",
    description: "Alerts the user that videos are being loaded",
  },
  link_apps: {
    message: "ऐप्स",
    description: "Title of link to open the Apps page",
  },
  link_history: {
    message: "इतिहास",
    description: "Title of link to open the History page",
  },
  link_bookmarks: {
    message: "बुकमार्क",
    description: "Title of link to open the Bookmarks page",
  },
  title_settings: {
    message: "समायोजन",
    description: "Title of the settings dropdown",
  },
  toggle_topsites: {
    message: "सर्वाधिक देखा गया",
    description: "Visibility toggle for top sites",
  },
  toggle_logo: {
    message: "UseDelight लोगो",
    description: "Visibility toggle for the UseDelight logo",
  },
  toggle_launchers: {
    message: "लांचरों",
    description: "Visibility toggle for Chrome launcher links",
  },
  toggle_weather: {
    message: "मौसम और समय",
    description: "Visibility toggle for the weather and time widget",
  },
  toggle_credits: {
    message: "वीडियो क्रेडिट",
    description: "Visibility toggle for the video's credit information",
  },
  toggle_video: {
    message: "वीडियो चलाएं",
    description: "Play toggle for the video",
  },
  toggle_demo: {
    message: "प्रदर्शन दिखाएँ",
    description: "Show the demo popup",
  },
  toggle_poplinks: {
    message: "लोकप्रिय लिंक",
    description: "Show the Popular Links",
  },
  toggle_search: {
    message: "खोज",
    description: "Show the Search Box",
  },
  link_review: {
    message: "हमें रेटिंग देंÍ",
    description: "Title of link to review UseDelight",
  },
  add_to_chrome: {
    message: "क्रोम में जोडे",
    description: "Link to UseDelight in Chrome Store",
  },
  about_us: {
    message: "UseDelight के बारे में",
    description: "Link to UseDelight About Us Page",
  },
  add_to_your_browser: {
    message: "अपने ब्राउज़र में जोड़ें",
    description: "Button text for Add to your browser",
  },
  add_to_browser: {
    message: "ब्राउज़र में जोड़ें",
    description: "Button text for Add to browser",
  },
  available_for: {
    message: "के लिए उपलब्ध है: ",
    description: "Text for Available for",
  },
  available_in_chrome: {
    message: "क्रोम में उपलब्ध है",
    description: "Text for Available in Chrome",
  },
  available_in_firefox: {
    message: "फ़ायरफ़ॉक्स में उपलब्ध है",
    description: "Text for Available in Firefox",
  },
  footer_about_header: {
    message: "UseDelight - Nature Wallpaper HD Video Background",
    description: "Header text in the About footer",
  },
  footer_about_text: {
    message:
      "1500 से अधिक HD वीडियो पृष्ठभूमि में से एक के लिए अपने ब्राउज़र का नया टैब खोलें, जिसमें समुद्र, सर्दियों के दृश्य, जंगलों और बीच में सब कुछ है।",
    description: "Text in the About footer",
  },
  our_policies: {
    message: "हमारी नीतियां",
    description: "Text for Our Policies",
  },
  terms_of_use: {
    message: "उपयोग की शर्तें",
    description: "Text for Terms of Use",
  },
  privacy_policy: {
    message: "गोपनीयता नीति",
    description: "Text for Privacy Policy",
  },
  cookies_policy: {
    message: "कुकीज़ नीति",
    description: "Text for Cookies Policy",
  },
  contact: {
    message: "संपर्क करें",
    description: "Text for Contact",
  },
  blog: {
    message: "ब्लॉग",
    description: "Text for Blog",
  },
  search: {
    message: "खोज",
    description: "Placeholder text for Search box",
  },
  sponsor_title: {
    message: "Sponsor:Squarespace",
    description: "Title of link to Pagecloud sponsor",
  },
  link_about: {
    message: "के बारे में",
    description: "Title of link to open the About dialogue",
  },
  placeholder_search_cities: {
    message: "शहरों की खोज करें",
    description: "Placeholder text in the city weather search input",
  },
  weather_search_prompt: {
    message:
      "ऊपर खोज क्षेत्र का उपयोग करके किसी शहर का मौसम और स्थानीय समय देखें।",
    description: "Prompt to search shown when no weather locations are saved",
  },
  open_weather_preferences: {
    message: "नया शहर जोड़ें",
    description: "Text for button to open weather preferences",
  },
  close_weather_preferences: {
    message: "किया हुआ",
    description: "Text for button to close weather preferences",
  },
  did_you_mean: {
    message: "क्या आपका मतलब है:",
    description: "Text to indicate other suggestions",
  },
  location_not_found: {
    message: "क्षमा करें, हमें वह स्थान नहीं मिला।",
    description: "Displayed when a searched location returned no results",
  },
  configure_location_prompt: {
    message: "समय और तापमान सेटिंग्स कॉन्फ़िगर करें:",
    description:
      "Prompt to configure the currently-previewing city before adding",
  },
  add_location_text: {
    message: "मेरे शहरों में जोड़ें",
    description:
      "Button to add currently-searched city to list of saved locations",
  },
  existing_city_message: {
    message: "आपने पहले ही इस शहर को जोड़ लिया है!",
    description:
      "Displayed when a user attempts to add a city already in their list of cities.",
  },
  at_location_limit: {
    message:
      "आप 4 स्थान की सीमा तक पहुँच गए हैं। एक और जोड़ने से पहले एक शहर निकालें।",
    description:
      "Warning to indicate the user has added the maximum allowed amount of locations",
  },
  donation_header: {
    message: "हैलो साथी UseDelightians :)",
    description: "Header text of donation popup",
  },
  donation_text_1: {
    message:
      "मैं माइक हूँ, UseDelight के पीछे रचनात्मक और मेरे माफी अगर यह पॉप-अप कष्टप्रद है। यदि UseDelight आपके जीवन में थोड़ी खुशी लाता है, तो कृपया एक कप चाय और दोपहर के भोजन के बीच, मासिक या एक बार के समर्थक बनने पर विचार करें।",
    description:
      "First paragraph of Donation note to be displayed on donation popup",
  },
  donation_text_2: {
    message:
      "डेढ़ दशक से अधिक समय तक, UseDelight मुक्त रही है। विस्मयकारी वीडियो का पता लगाने और उन्हें होस्ट करने के लिए पैसे और साथ ही दुनिया भर में तेजी से 24/7 लोड करने के लिए उन्हें संपादित करने में समय लगता है।",
    description:
      "Second paragraph of Donation note to be displayed on donation popup",
  },
  donation_text_3: {
    message:
      "आपके सहयोग से, हम UseDelight को आम जनता के लिए निःशुल्क रख सकते हैं। यदि आप कर सकते हैं, तो हर छोटी सी मदद करता है।",
    description:
      "Third paragraph of Donation note to be displayed on donation popup",
  },
  donation_text_4: {
    message: "मैं हमेशा आभारी हूं कि आप UseDelight चुनते हैं!",
    description:
      "Fourth paragraph of Donation note to be displayed on donation popup",
  },
  donate_button: {
    message: "पेपैल के माध्यम से एक दान करें",
    description: "Donation button text",
  },
  link_promo: {
    message: "अनन्य प्रोमो!",
    description: "Promo link text on Settings menu",
  },
  link_donate: {
    message: "दान करना",
    description: "Donation link text on Settings menu",
  },
  poll_text: {
    message: "क्या आप यहां अधिक यात्रा सौदे देखना चाहेंगे?",
    description: "Question for the promotion poll",
  },
  poll_yes: {
    message: "हां, मुझे पैसे बचाना पसंद है",
    description: "Text in the YES button in the promotion poll",
  },
  poll_no: {
    message: "नहीं, धन्यवाद, मुझे लगता है कि मैं पूरी कीमत चुकाऊंगा",
    description: "Text in the NO button in the promotion poll",
  },
  demo_header: {
    message: "आइए एक त्वरित ट्यूटोरियल देखें",
    description: "Header text of demo popup",
  },
  demo_button: {
    message: "वीडियो चलाएं",
    description: "Demo play video button text",
  },
  popularlinks_header: {
    message: "नीचे लोकप्रिय उपयोगी लिंक दिए गए हैं",
    description: "Header text of popular links popup",
  },
  popularlinks_icon: {
    message: "लोकप्रिय लिंक",
    description: "Text of the popular links icon.",
  },
  popular: {
    message: "लोकप्रिय",
    description: "Text of the popular icon.",
  },
  shopping: {
    message: "खरीदारी",
    description: "Text of the shopping icon.",
  },
  travel: { message: "यात्रा", description: "Text of the travel icon." },
  games: { message: "वीडियो गेम", description: "Text of the games icon." },
  games_charity: {
    message: "चैरिटी के लिए खेल",
    description: "Text of the Games for Charity icon.",
  },
  other_games: {
    message: "अन्य खेल",
    description: "Text of the Other Games icon.",
  },
  donate: { message: "दान करना", description: "Text of the donate icon." },
  all: { message: "सब", description: "Text of the 'All' icon." },
  general: {
    message: "सामान्य",
    description: "Text for the General settings",
  },
  general_desc: {
    message: "अपने अनुभव को अनुकूलित करें",
    description: "Text for the General settings description",
  },
  video: {
    message: "वीडियो",
    description: "Text for Video",
  },
  video_desc: {
    message: "वीडियो पृष्ठभूमि के लिए सेटिंग्स",
    description: "Text for Video settings description",
  },
  show_only_favorites: {
    message: "केवल पसंदीदा वीडियो दिखाएं",
    description: "Text for show only favorite videos setting",
  },
  multiple_videos: {
    message: "प्रति सत्र एकाधिक वीडियो",
    description: "Text for multiple videos per session setting",
  },
  multiple_video_headsup: {
    message:
      "चेतावनी! इस विकल्प के लिए तेज़ इंटरनेट कनेक्शन की आवश्यकता होती है।",
    description: "Text for multiple videos warning",
  },
  number_videos: {
    message: "उपयोग करने के लिए वीडियो की संख्या",
    description: "Text for number of videos to use",
  },
  sign_in: {
    message: "साइन इन करें",
    description: "Text for Sign-in",
  },
  sign_up: {
    message: "साइन अप करें",
    description: "Text for Sign-up",
  },
  sign_out: {
    message: "प्रस्थान करें",
    description: "Text for Sign-out",
  },
  profile: {
    message: "प्रोफ़ाइल",
    description: "Text for Profile",
  },
  user_profile: {
    message: "उपयोगकर्ता प्रोफ़ाइल",
    description: "Text for User Profile",
  },
  profile_desc: {
    message: "आप इस समय लॉग इन हैं।",
    description: "Text for User Profile Description",
  },
  sign_up_desc: {
    message: "साइन अप करके अपने खाते को सिंक करें।",
    description: "Text for Sign-up description",
  },
  sign_in_desc: {
    message: "साइन इन करके अपने खाते को सिंक करें।",
    description: "Text for Sign-in description",
  },
  email: {
    message: "ईमेल पता",
    description: "Text for E-mail address",
  },
  password: {
    message: "पासवर्ड",
    description: "Text for Password",
  },
  confirm_password: {
    message: "पासवर्ड की पुष्टि कीजिये",
    description: "Text for Confirm Password",
  },
  display_name: {
    message: "प्रदर्शित होने वाला नाम",
    description: "Text for Display Name",
  },
  good_morning: {
    message: "शुभ प्रभात",
    description: "Text for Good Morning",
  },
  good_afternoon: {
    message: "गुड आफ़्टरनून।",
    description: "Text for Good Afternoon",
  },
  good_evening: {
    message: "गुड ईवनिंग।",
    description: "Text for Good Evening",
  },
  toggle_greeting: {
    message: "शुभकामनाएँ",
    description: "Text for Greeting",
  },
  sticky_greeting: {
    message: "चिपचिपा अभिवादन",
    description: "Text for Sticky Greeting",
  },
  rateus_hello: {
    message: "हैलो दोस्त",
    description: "Text for Hello Friend in Rate Us popup",
  },
  rateus_line1: {
    message:
      "UseDelight एक व्यक्तिगत डैशबोर्ड है जिसे आपको खुशी और दक्षता लाने के लिए डिज़ाइन किया गया है।",
    description: "Text for line 1 message in Rate Us popup",
  },
  rateus_line2: {
    message:
      "हम साप्ताहिक अपडेट करने और नई सुविधाओं को जोड़ने के लिए कड़ी मेहनत करते हैं।",
    description: "Text for line 2 message in Rate Us popup",
  },
  rateus_line3: {
    message:
      "आपकी तरह के शब्द हमें पर्दे के पीछे खुशी ला सकते हैं। कृपया कुछ सेकंड लें हमें बताएं कि हम कैसे कर रहे हैं।",
    description: "Text for line 3 message in Rate Us popup",
  },
  rateus_have_suggestion: {
    message: "मेरे पास एक सुझाव है",
    description: "Text for I have a suggestion button in Rate Us popup",
  },
  rateus_great_job: {
    message: "महान काम - धन्यवाद!",
    description: "Text for Great job thank you button in Rate Us popup",
  },
  rateus_suggest_message: {
    message:
      "कृपया हमें बताएं कि हम अपने प्रदर्शन को कैसे बेहतर बना सकते हैं क्योंकि हम तुरंत प्रतिक्रिया देते हैं और आपकी प्रतिक्रिया को बहुत गंभीरता से लेते हैं।",
    description: "Text for Suggestion message in Rate Us popup",
  },
  rateus_comments_placeholder: {
    message: "टिप्पणियाँ / सुझाव",
    description: "Text for Suggestion placeholder in Rate Us popup",
  },
  rateus_name_placeholder: {
    message: "नाम: (वैकल्पिक)",
    description: "Text for name placeholder in Rate Us popup",
  },
  error_message: {
    message: "कुछ गलत हो गया। कृपया पुन: प्रयास करें।",
    description: "Text for generic error message",
  },
  news: {
    message: "समाचार",
    description: "Text for News",
  },
  submit: {
    message: "प्रस्तुत",
    description: "Text for Submit",
  },
  cancel: {
    message: "रद्द करना",
    description: "Text for Cancel",
  },
};
