import axios from "axios";
import { appStorageRemove, appStorageSet } from "../components/Utils/Utils";
import { BASE_API_URL } from "../constants/commonConstants";
import {
	CANCEL_SUBSCRIPTION_FAIL,
	CANCEL_SUBSCRIPTION_REQUEST,
	CANCEL_SUBSCRIPTION_SUCCESS,
	GET_CUSTOMER_PORTAL_SESSION_FAIL,
	GET_CUSTOMER_PORTAL_SESSION_REQUEST,
	GET_CUSTOMER_PORTAL_SESSION_SUCCESS,
	GET_PLANINFO_FAIL,
	GET_PLANINFO_REQUEST,
	GET_PLANINFO_SUCCESS,
	PRICES_FAIL,
	PRICES_REQUEST,
	PRICES_SUCCESS,
	SUBS_CREATE_CUSTOMER_FAIL,
	SUBS_CREATE_CUSTOMER_REQUEST,
	SUBS_CREATE_CUSTOMER_SUCCESS
} from '../constants/subscriptionConstants';
import { SET_PLAN_INFO } from "../constants/userConstants";

export const createCustomer = (email) => async (dispatch) => {
  try {
    dispatch({
      type: SUBS_CREATE_CUSTOMER_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${BASE_API_URL}/subscriptions/create-customer`,
      { email },
      config
    );

    if (data.success === true) {
      dispatch({
        type: SUBS_CREATE_CUSTOMER_SUCCESS,
        payload: data,
      });
      appStorageSet({
        key: "customer",
        value: data,
      });
    } else {
      dispatch({
        type: SUBS_CREATE_CUSTOMER_FAIL,
        payload: data.result.error,
      });
    }
  } catch (error) {
    dispatch({
      type: SUBS_CREATE_CUSTOMER_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};

export const cancelSubscription = (userInfo) => async (dispatch) => {
  try {
    dispatch({
      type: CANCEL_SUBSCRIPTION_REQUEST,
    });

    const authorization =
      userInfo && userInfo.token ? `Bearer ${userInfo.token}` : "";

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
    };

    const { data } = await axios.post(
      `${BASE_API_URL}/subscriptions/cancel`,
      { email: userInfo.email },
      config
    );

    if (data.success === true) {
      dispatch({
        type: CANCEL_SUBSCRIPTION_SUCCESS,
        payload: data.data,
      });
      dispatch({
        type: SET_PLAN_INFO,
        payload: null,
      });
      appStorageRemove("planInfo");
    } else {
      dispatch({
        type: CANCEL_SUBSCRIPTION_FAIL,
        payload: data.result.error,
      });
    }
  } catch (error) {
    dispatch({
      type: CANCEL_SUBSCRIPTION_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};

export const getPlanInfo = (userInfo) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PLANINFO_REQUEST,
    });

    const authorization =
      userInfo && userInfo.token ? `Bearer ${userInfo.token}` : "";

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
    };

    const { data } = await axios.post(
      `${BASE_API_URL}/subscriptions/get-plan`,
      { email: userInfo.email },
      config
    );

    if (data.success === true) {
      dispatch({
        type: GET_PLANINFO_SUCCESS,
        payload: data.data,
      });
      dispatch({
        type: SET_PLAN_INFO,
        payload: data.data,
      });
      appStorageSet({ key: "planInfo", value: data.data });
    } else {
      dispatch({
        type: GET_PLANINFO_FAIL,
        payload: data.result.error,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_PLANINFO_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};

export const getPrices = () => async (dispatch) => {
  try {
    dispatch({
      type: PRICES_REQUEST,
    });

    const { data } = await axios.get(
      `${BASE_API_URL}/subscriptions/get-prices`
    );

    if (data.success === true) {
      dispatch({
        type: PRICES_SUCCESS,
        payload: data.data,
      });
    } else {
      dispatch({
        type: PRICES_FAIL,
        payload: data.result.error,
      });
    }
  } catch (error) {
    dispatch({
      type: PRICES_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};

export const getCustomerPortalSession = (userInfo) => async (dispatch) => {
	try {
		dispatch({
			type: GET_CUSTOMER_PORTAL_SESSION_REQUEST,
		});

		const authorization =
			userInfo && userInfo.token ? `Bearer ${userInfo.token}` : "";

		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: authorization,
			},
		};

		const { data } = await axios.post(
			`${BASE_API_URL}/subscriptions/create-billing-portal-session`,
			{ email: userInfo.email },
			config
		);

		if (data.success === true) {
			dispatch({
				type: GET_CUSTOMER_PORTAL_SESSION_SUCCESS,
				payload: data.data,
			});
		} else {
			dispatch({
				type: GET_CUSTOMER_PORTAL_SESSION_FAIL,
				payload: data.result.error,
			});
		}
	} catch (error) {
		dispatch({
			type: GET_CUSTOMER_PORTAL_SESSION_FAIL,
			payload:
				error.response && error.response.data
					? error.response.data.error
					: error.message,
		});
	}
}
