import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useHistory } from "react-router-dom";

import { logout } from "../actions/userActions";
import { TEST_MODE } from "../constants/commonConstants";

const Header = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const history = useHistory();

  const handleLogout = () => {
    dispatch(logout());
  };

  useEffect(() => {
    if (userInfo && userInfo.loggedOut) {
      history.push("/");
    }
  }, [history, userInfo]);

  return (
    <header>
      <Navbar bg="primary" variant="dark" expand="lg" collapseOnSelect>
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>
              <img src="../assets/icons/usedelight.png" alt="UseDelight logo" />
            </Navbar.Brand>
          </LinkContainer>
          {TEST_MODE && (
            <h4>
              <Badge variant="warning">Test Mode</Badge>
            </h4>
          )}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link href="/">
                <i className="fas fa-home"></i> UseDelight
              </Nav.Link>
              {/*<Nav.Link href="#">
                <i className="fas fa-puzzle-piece"></i> Extension
                </Nav.Link>*/}
              {userInfo && userInfo.name ? (
                <NavDropdown title={userInfo.name} id="username">
                  <NavDropdown.Item onClick={() => history.push("/account")}>
                    Account
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={handleLogout}>
                    Sign out
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <LinkContainer to="/login">
                  <Nav.Link>
                    <i className="fas fa-sign-in-alt"></i> Sign in
                  </Nav.Link>
                </LinkContainer>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
