export const BASE_API_URL = "https://api.usedelight.com/api/v1";
// export const BASE_API_URL = "http://localhost:5000/api/v1";
export const GA_TRACKING_ID = "UA-155863105-1";
export const GA_CLIENT_ID = "5f52d60fdc6f4466b469d181";
export const LOGGED_IN = "loggedIn";
export const NAME = "name";
export const EMAIL = "email";
export const TOKEN = "token";
export const HOMEPAGE_URL = "https://usedelight.com";
export const TEST_MODE = false;
