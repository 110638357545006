import React, { useEffect, useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import _ from "lodash";

import { createCustomer } from "../actions/subscriptionActions";
import FormContainer from "../components/FormContainer";

const UpgradeScreen = ({ history }) => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const createCustomerInfo = useSelector((state) => state.createCustomerInfo);
  const planInfoState = useSelector((state) => state.planState);
  const { planInfo } = planInfoState;
  const { customer } = createCustomerInfo;
  const { userInfo } = userLogin;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (planInfo && !_.isEmpty(planInfo)) {
      console.log("redirecting to /account");
      history.push("/account");
    }

    if (!userInfo || !userInfo.email) {
      console.log("redirecting to /register");
      history.push("/register?redirect=upgrade");
    }

    if (customer) {
      console.log("redirecting to /checkout");
      history.push("/checkout");
    }
  }, [customer, history, planInfo, userInfo]);

  const continueHandler = (evt) => {
    evt.preventDefault();
    setLoading(true);
    dispatch(createCustomer(userInfo.email));
  };

  return (
    <FormContainer>
      <h3>Upgrade - Email Confirmation</h3>
      <Alert variant="light">{userInfo && userInfo.email}</Alert>
      <Button
        variant="primary"
        type="button"
        className="mr-3"
        onClick={continueHandler}
        disabled={loading}
      >
        Continue
      </Button>
      <LinkContainer to="/login">
        <Button variant="secondary">Cancel</Button>
      </LinkContainer>
    </FormContainer>
  );
};

export default UpgradeScreen;
