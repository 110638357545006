import React from "react";
import { Alert, Button, Container, Jumbotron, Table } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import FeatureRow from "../components/FeatureRow";
import "./HomeScreen.css";

const HomeScreen = () => {
  return (
    <Container>
      <Jumbotron className="text-center">
        <h1 className="display-5">UseDelight Pro</h1>
        <p className="lead">
          More delight in customizing your dashboard with added customizations,
          integrations, widgets, and more features to enjoy!
        </p>
        <p>
          Get started for only <strong>$1.25/month!</strong>
        </p>
        <LinkContainer to="/upgrade">
          <Button variant="primary">Upgrade</Button>
        </LinkContainer>
      </Jumbotron>
      <Alert className="text-center bg-warning my-1">
        <h5 className="my-1">
          Just Released! Lifetime PRO - only a few spots remaining for this
          limited time offer
        </h5>
      </Alert>
      <Table responsive="sm">
        <thead>
          <tr>
            <th className="align-middle">
              <h3>Features</h3>
            </th>
            <th className="text-center">
              <h4>Free</h4>
              <div>$0/month</div>
            </th>
            <th className="text-center table-success">
              <h4>PRO</h4>
              <div>$1.25/month*</div>
            </th>
            <th className="text-center table-warning">
              <h4>Lifetime PRO!</h4>
              <div>
                $52.97 <br /> One-time payment
              </div>
            </th>
          </tr>
        </thead>
        <thead>
          <tr className="table-info">
            <th colSpan="4">Exclusive to Lifetime PRO</th>
          </tr>
        </thead>
        <tbody>
          <FeatureRow
            text="Personal Thank You video from our Founder, Mike D."
            isFree={false}
            isLifetime={true}
          />
          <FeatureRow
            text="Access all PRO features for life"
            isFree={false}
            isLifetime={true}
          />
          <FeatureRow text="No Future Fees" isFree={false} isLifetime={true} />
          <FeatureRow
            text="Limited Time Offer"
            isFree={false}
            isLifetime={true}
          />
        </tbody>
        <thead>
          <tr className="table-info">
            <th colSpan="4">Backgrounds</th>
          </tr>
        </thead>
        <tbody>
          <FeatureRow text="New video per session" isFree={true} />
          <FeatureRow text="Multiple videos per session" isFree={false} />
          <FeatureRow text="Play or pause video" isFree={true} />
          <FeatureRow text="Video Credits (show/Hide)" isFree={true} />
          <FeatureRow text="Add video to your favorites" isFree={true} />
          <FeatureRow text="Choose to hide video" isFree={true} />
          <FeatureRow text="Show ONLY favorite videos" isFree={false} />
        </tbody>
        <thead>
          <tr className="table-info">
            <th colSpan="4">Weather</th>
          </tr>
        </thead>
        <tbody>
          <FeatureRow text="Basic Weather + Time" isFree={true} />
          <FeatureRow text="7-Day Weather" isFree={false} />
          <FeatureRow text="Hourly Weather Forecasts" isFree={false} />
          <FeatureRow text="Multiple Weather Locations" isFree={false} />
        </tbody>
        <thead>
          <tr className="table-info">
            <th colSpan="4">Links</th>
          </tr>
        </thead>
        <tbody>
          <FeatureRow text="Search (Show/Hide)" isFree={true} />
          <FeatureRow text="Most Visited Links (Show/Hide)" isFree={true} />
          <FeatureRow text="Logo (Show/Hide)" isFree={false} />
          <FeatureRow text="Add Custom Links (Show/Hide)" isFree={false} />
          <FeatureRow text="Popular Links (Show/Hide)" isFree={true} />
          <FeatureRow
            text="Launchers - Bookmarks, History, Apps (Show/Hide)"
            isFree={true}
          />
        </tbody>
        <thead>
          <tr className="table-info">
            <th colSpan="4">Customizations</th>
          </tr>
        </thead>
        <tbody>
          <FeatureRow text="Create User Profile" isFree={true} />
          <FeatureRow
            text="Sync User Settings across devices and Browsers"
            isFree={false}
          />
          <FeatureRow text="Change Search Engine" isFree={true} />
          <FeatureRow text="Time-based greeting with name" isFree={true} />
          <FeatureRow text="Sticky Greeting (Always show)" isFree={true} />
          <FeatureRow text="Custom Greeting text" isFree={false} />
        </tbody>
        <thead>
          <tr className="table-info">
            <th colSpan="4">News</th>
          </tr>
        </thead>
        <tbody>
          <FeatureRow text="News feed" isFree={true} />
        </tbody>
        <thead>
          <tr className="table-info">
            <th colSpan="4">Inspiration</th>
          </tr>
        </thead>
        <tbody>
          <FeatureRow text="Quotes" isFree={true} />
          <FeatureRow
            text="Add your own quotes, display previous or favorites quotes"
            isFree={true}
          />
        </tbody>
        <thead>
          <tr className="table-info">
            <th colSpan="4">Support</th>
          </tr>
        </thead>
        <tbody>
          <FeatureRow text="Support new UseDelight features 😊" isFree={true} />
          <FeatureRow
            text="Premier access to new features and improvements"
            isFree={false}
          />
        </tbody>
      </Table>
      <Jumbotron className="text-center">
        <p>
          Get started for only <strong>$1.25/month!</strong>
        </p>
        <LinkContainer to="/upgrade">
          <Button variant="primary">Upgrade</Button>
        </LinkContainer>
      </Jumbotron>
      <p className="text-muted">
        <em>* Annual Plan</em>
      </p>
    </Container>
  );
};

export default HomeScreen;
