export const esMessages = {
  app_name: {
    message: "Chrome Theme Infinity New Tab Backgrounds HD",
    description: "The title of the application,displayed in the web store",
  },
  app_short_name: {
    message: "UseDelight",
    description:
      "The short title of the application,displayed in the web store and from the app launcher",
  },
  app_description: {
    message:
      "Fondos de pantalla de video gratis increíble página de inicio con clima, motor de búsqueda, juegos",
    description:
      "The description of the application,displayed in the web store",
  },
  new_tab: {
    message: "Nueva pestaña",
    description: "Title of the New Tab page",
  },
  no_connection: {
    message: "Sin conexión a Internet",
    description: "Alerts the user that they have no connection to the internet",
  },
  battery_mode: {
    message: "Modo ecológico",
    description: "Alerts the user that they are seeing the battery mode",
  },
  loading_videos: {
    message: "Cargando videos ...",
    description: "Alerts the user that videos are being loaded",
  },
  link_apps: {
    message: "Aplicaciones",
    description: "Title of link to open the Apps page",
  },
  link_history: {
    message: "Historia",
    description: "Title of link to open the History page",
  },
  link_bookmarks: {
    message: "Marcadores",
    description: "Title of link to open the Bookmarks page",
  },
  title_settings: {
    message: "Configuraciones",
    description: "Title of the settings dropdown",
  },
  toggle_topsites: {
    message: "Sitios más visitados",
    description: "Visibility toggle for top sites",
  },
  toggle_logo: {
    message: "UseDelight Logotipo",
    description: "Visibility toggle for the UseDelight logo",
  },
  toggle_launchers: {
    message: "Enlaces de lanzadores",
    description: "Visibility toggle for Chrome launcher links",
  },
  toggle_weather: {
    message: "Tiempo/hora",
    description: "Visibility toggle for the weather and time widget",
  },
  toggle_credits: {
    message: "Crédito de video",
    description: "Visibility toggle for the video's credit information",
  },
  toggle_video: {
    message: "Reproduce el video",
    description: "Play toggle for the video",
  },
  toggle_demo: {
    message: "Mostrar demostración",
    description: "Show the demo popup",
  },
  toggle_poplinks: {
    message: "Enlaces populares",
    description: "Show the Popular Links",
  },
  toggle_search: {
    message: "Buscar",
    description: "Show the Search Box",
  },
  link_review: {
    message: "Nos califica",
    description: "Title of link to review UseDelight",
  },
  add_to_chrome: {
    message: "Añadir a Chrome",
    description: "Link to UseDelight in Chrome Store",
  },
  about_us: {
    message: "Acerca de UseDelight",
    description: "Link to UseDelight About Us Page",
  },
  add_to_your_browser: {
    message: "Agregar a su navegador",
    description: "Button text for Add to your browser",
  },
  add_to_browser: {
    message: "Agregar al navegador",
    description: "Button text for Add to browser",
  },
  available_for: {
    message: "Disponible para: ",
    description: "Text for Available for",
  },
  available_in_chrome: {
    message: "Disponible en Chrome",
    description: "Text for Available in Chrome",
  },
  available_in_firefox: {
    message: "Disponible en Firefox",
    description: "Text for Available in Firefox",
  },
  footer_about_header: {
    message: "UseDelight - Nature Wallpaper HD Video Background",
    description: "Header text in the About footer",
  },
  footer_about_text: {
    message:
      "Abra la nueva pestaña de su navegador a uno de los más de 1500 fondos de video HD con paisajes que van desde océanos, escenas invernales, bosques y todo lo demás.",
    description: "Text in the About footer",
  },
  our_policies: {
    message: "Nuestras Políticas",
    description: "Text for Our Policies",
  },
  terms_of_use: {
    message: "Términos de Uso",
    description: "Text for Terms of Use",
  },
  privacy_policy: {
    message: "Política de privacidad",
    description: "Text for Privacy Policy",
  },
  cookies_policy: {
    message: "Política de cookies",
    description: "Text for Cookies Policy",
  },
  contact: {
    message: "Contacto",
    description: "Text for Contact",
  },
  blog: {
    message: "Blog",
    description: "Text for Blog",
  },
  search: {
    message: "Buscar",
    description: "Placeholder text for Search box",
  },
  sponsor_title: {
    message: "Sponsor:Squarespace",
    description: "Title of link to Pagecloud sponsor",
  },
  link_about: {
    message: "Acerca de",
    description: "Title of link to open the About dialogue",
  },
  placeholder_search_cities: {
    message: "Buscar ciudades",
    description: "Placeholder text in the city weather search input",
  },
  weather_search_prompt: {
    message:
      "Vea el clima y la hora local de una ciudad usando el campo de búsqueda arriba.",
    description: "Prompt to search shown when no weather locations are saved",
  },
  open_weather_preferences: {
    message: "Agregar ciudad",
    description: "Text for button to open weather preferences",
  },
  close_weather_preferences: {
    message: "Hecho",
    description: "Text for button to close weather preferences",
  },
  did_you_mean: {
    message: "Querías decir:",
    description: "Text to indicate other suggestions",
  },
  location_not_found: {
    message: "Lo sentimos, no pudimos encontrar esa ubicación.",
    description: "Displayed when a searched location returned no results",
  },
  configure_location_prompt: {
    message: "Configure los ajustes de tiempo y temperatura:",
    description:
      "Prompt to configure the currently-previewing city before adding",
  },
  add_location_text: {
    message: "Agregar a mis ciudades",
    description:
      "Button to add currently-searched city to list of saved locations",
  },
  existing_city_message: {
    message: "Ya agregaste esta ciudad!",
    description:
      "Displayed when a user attempts to add a city already in their list of cities.",
  },
  at_location_limit: {
    message:
      "Has alcanzado el límite de 4 ubicaciones. Eliminar una ciudad antes de agregar otra.",
    description:
      "Warning to indicate the user has added the maximum allowed amount of locations",
  },
  donation_header: {
    message: "Hola UseDelightians :)",
    description: "Header text of donation popup",
  },
  donation_text_1: {
    message:
      "Soy Mike, creativo detrás de UseDelight y mis disculpas si esta ventana emergente es molesta. Si UseDelight trae un poco de alegría a su vida, considere convertirse en un colaborador mensual, o por única vez, entre una taza de té y el almuerzo.",
    description:
      "First paragraph of Donation note to be displayed on donation popup",
  },
  donation_text_2: {
    message:
      "Durante más de media década, UseDelight ha sido gratuito. Se necesita tiempo para localizar videos asombrosos y editarlos, así como dinero para alojarlos y mantenerlos cargándose rápidamente las 24 horas del día, los 7 días de la semana en todo el mundo.",
    description:
      "Second paragraph of Donation note to be displayed on donation popup",
  },
  donation_text_3: {
    message:
      "Con su apoyo, podemos mantener UseDelight gratis para las masas. Si puede, todo ayuda.",
    description:
      "Third paragraph of Donation note to be displayed on donation popup",
  },
  donation_text_4: {
    message: "¡Siempre estaré agradecido de que elija UseDelight!",
    description:
      "Fourth paragraph of Donation note to be displayed on donation popup",
  },
  donate_button: {
    message: "Hacer una donación de PayPal",
    description: "Donation button text",
  },
  link_promo: {
    message: "Promoción exclusiva!",
    description: "Promo link text on Settings menu",
  },
  link_donate: {
    message: "Donar",
    description: "Donation link text on Settings menu",
  },
  poll_text: {
    message: "¿Le gustaría ver más ofertas de viajes aquí?",
    description: "Question for the promotion poll",
  },
  poll_yes: {
    message: "Si me gusta ahorrar dinero",
    description: "Text in the YES button in the promotion poll",
  },
  poll_no: {
    message: "No, gracias, supongo que pagaré el precio completo",
    description: "Text in the NO button in the promotion poll",
  },
  demo_header: {
    message: "Veamos un tutorial rápido",
    description: "Header text of demo popup",
  },
  demo_button: {
    message: "Reproducir el video",
    description: "Demo play video button text",
  },
  popularlinks_header: {
    message: "A continuación se muestran enlaces útiles populares",
    description: "Header text of popular links popup",
  },
  popularlinks_icon: {
    message: "Enlaces populares",
    description: "Text of the popular links icon.",
  },
  popular: {
    message: "Popular",
    description: "Text of the popular icon.",
  },
  shopping: {
    message: "Compras",
    description: "Text of the shopping icon.",
  },
  travel: { message: "Viaje", description: "Text of the travel icon." },
  games: { message: "Juegos", description: "Text of the games icon." },
  games_charity: {
    message: "Juegos de caridad",
    description: "Text of the Games for Charity icon.",
  },
  other_games: {
    message: "Otros juegos",
    description: "Text of the Other Games icon.",
  },
  donate: { message: "Donar", description: "Text of the donate icon." },
  all: { message: "Todas", description: "Text of the 'All' icon." },
  general: {
    message: "General",
    description: "Text for the General settings",
  },
  general_desc: {
    message: "Personaliza tu experiencia",
    description: "Text for the General settings description",
  },
  video: {
    message: "Vídeo",
    description: "Text for Video",
  },
  video_desc: {
    message: "Personalizaciones de fondo de video",
    description: "Text for Video settings description",
  },
  show_only_favorites: {
    message: "Mostrar solo videos favoritos",
    description: "Text for show only favorite videos setting",
  },
  multiple_videos: {
    message: "Varios videos por sesión",
    description: "Text for multiple videos per session setting",
  },
  multiple_video_headsup: {
    message: "¡Aviso! Esta opción requiere una conexión rápida a Internet.",
    description: "Text for multiple videos warning",
  },
  number_videos: {
    message: "Cantidad de videos para usar",
    description: "Text for number of videos to use",
  },
  sign_in: {
    message: "Registrarse",
    description: "Text for Sign-in",
  },
  sign_up: {
    message: "Regístrate",
    description: "Text for Sign-up",
  },
  sign_out: {
    message: "Desconectar",
    description: "Text for Sign-out",
  },
  profile: {
    message: "Perfil",
    description: "Text for Profile",
  },
  user_profile: {
    message: "Perfil del usuario",
    description: "Text for User Profile",
  },
  profile_desc: {
    message: "Usted está actualmente conectado.",
    description: "Text for User Profile Description",
  },
  sign_up_desc: {
    message: "Sincronice su cuenta registrándose.",
    description: "Text for Sign-up description",
  },
  sign_in_desc: {
    message: "Sincronice su cuenta iniciando sesión.",
    description: "Text for Sign-in description",
  },
  email: {
    message: "Email",
    description: "Text for E-mail address",
  },
  password: {
    message: "Contraseña",
    description: "Text for Password",
  },
  confirm_password: {
    message: "Confirmar contraseña",
    description: "Text for Confirm Password",
  },
  display_name: {
    message: "Nombre para mostrar",
    description: "Text for Display Name",
  },
  good_morning: {
    message: "Buenos días",
    description: "Text for Good Morning",
  },
  good_afternoon: {
    message: "Buenas tardes",
    description: "Text for Good Afternoon",
  },
  good_evening: {
    message: "¡Buena noches!",
    description: "Text for Good Evening",
  },
  toggle_greeting: {
    message: "Saludo",
    description: "Text for Greeting",
  },
  sticky_greeting: {
    message: "Saludo pegajoso",
    description: "Text for Sticky Greeting",
  },
  rateus_hello: {
    message: "Hola amigo",
    description: "Text for Hello Friend in Rate Us popup",
  },
  rateus_line1: {
    message:
      "UseDelight es un tablero personal diseñado para brindarle alegría y eficiencia.",
    description: "Text for line 1 message in Rate Us popup",
  },
  rateus_line2: {
    message:
      "Trabajamos duro para actualizar semanalmente y agregar nuevas funciones.",
    description: "Text for line 2 message in Rate Us popup",
  },
  rateus_line3: {
    message:
      "Tus amables palabras pueden traernos alegría entre bastidores. Tómese unos segundos para informarnos cómo estamos.",
    description: "Text for line 3 message in Rate Us popup",
  },
  rateus_have_suggestion: {
    message: "tengo una sugerencia",
    description: "Text for I have a suggestion button in Rate Us popup",
  },
  rateus_great_job: {
    message: "Buen trabajo - ¡Gracias!",
    description: "Text for Great job thank you button in Rate Us popup",
  },
  rateus_suggest_message: {
    message:
      "Háganos saber cómo podemos mejorar nuestro rendimiento, ya que respondemos con prontitud y nos tomamos muy en serio sus comentarios.",
    description: "Text for Suggestion message in Rate Us popup",
  },
  rateus_comments_placeholder: {
    message: "Comentarios / Sugerencias",
    description: "Text for Suggestion placeholder in Rate Us popup",
  },
  rateus_name_placeholder: {
    message: "Nombre: (Opcional)",
    description: "Text for name placeholder in Rate Us popup",
  },
  error_message: {
    message: "Algo salió mal. Inténtalo de nuevo.",
    description: "Text for generic error message",
  },
  news: {
    message: "Noticias",
    description: "Text for News",
  },
  submit: {
    message: "Enviar",
    description: "Text for Submit",
  },
  cancel: {
    message: "Cancelar",
    description: "Text for Cancel",
  },
};
