export const enMessages = {
  app_name: {
    message: "Chrome Theme Infinity New Tab Backgrounds HD",
    description: "The title of the application,displayed in the web store",
  },
  app_short_name: {
    message: "UseDelight",
    description:
      "The short title of the application,displayed in the web store and from the app launcher",
  },
  app_description: {
    message:
      "Free video wallpapers incredible startpage with weather, search engine, news, games",
    description:
      "The description of the application,displayed in the web store",
  },
  new_tab: {
    message: "New Tab",
    description: "Title of the New Tab page",
  },
  no_connection: {
    message: "No internet connection",
    description: "Alerts the user that they have no connection to the internet",
  },
  battery_mode: {
    message: "Eco mode",
    description: "Alerts the user that they are seeing the battery mode",
  },
  loading_videos: {
    message: "Loading videos...",
    description: "Alerts the user that videos are being loaded",
  },
  link_apps: {
    message: "Apps",
    description: "Title of link to open the Apps page",
  },
  link_history: {
    message: "History",
    description: "Title of link to open the History page",
  },
  link_bookmarks: {
    message: "Bookmarks",
    description: "Title of link to open the Bookmarks page",
  },
  title_settings: {
    message: "Settings",
    description: "Title of the settings dropdown",
  },
  toggle_topsites: {
    message: "Most visited",
    description: "Visibility toggle for top sites",
  },
  toggle_logo: {
    message: "UseDelight Logo",
    description: "Visibility toggle for the UseDelight logo",
  },
  toggle_launchers: {
    message: "Launchers",
    description: "Visibility toggle for Chrome launcher links",
  },
  toggle_weather: {
    message: "Weather & time",
    description: "Visibility toggle for the weather and time widget",
  },
  toggle_credits: {
    message: "Video credit",
    description: "Visibility toggle for the video's credit information",
  },
  toggle_video: {
    message: "Play video",
    description: "Play toggle for the video",
  },
  toggle_demo: {
    message: "Show Demo",
    description: "Show the demo popup",
  },
  toggle_poplinks: {
    message: "Popular Links",
    description: "Show the Popular Links",
  },
  toggle_search: {
    message: "Search",
    description: "Show the Search Box",
  },
  link_review: {
    message: "Rate Us",
    description: "Title of link to review UseDelight",
  },
  add_to_chrome: {
    message: "Add to Chrome",
    description: "Link to UseDelight in Chrome Store",
  },
  about_us: {
    message: "About UseDelight",
    description: "Link to UseDelight About Us Page",
  },
  add_to_your_browser: {
    message: "Add to your browser",
    description: "Button text for Add to your browser",
  },
  add_to_browser: {
    message: "Add to browser",
    description: "Button text for Add to browser",
  },
  available_for: {
    message: "Available for: ",
    description: "Text for Available for",
  },
  available_in_chrome: {
    message: "Available in Chrome",
    description: "Text for Available in Chrome",
  },
  available_in_firefox: {
    message: "Available in Firefox",
    description: "Text for Available in Firefox",
  },
  footer_about_header: {
    message: "UseDelight - Nature Wallpaper HD Video Background",
    description: "Header text in the About footer",
  },
  footer_about_text: {
    message:
      "Open your browser's new tab to one of over 1500 HD video backgrounds with sceneries ranging from oceans, winter scenes, forests, and everything in between.",
    description: "Text in the About footer",
  },
  our_policies: {
    message: "Our Policies",
    description: "Text for Our Policies",
  },
  terms_of_use: {
    message: "Terms of Use",
    description: "Text for Terms of Use",
  },
  privacy_policy: {
    message: "Privacy Policy",
    description: "Text for Privacy Policy",
  },
  cookies_policy: {
    message: "Cookies Policy",
    description: "Text for Cookies Policy",
  },
  contact: {
    message: "Contact",
    description: "Text for Contact",
  },
  blog: {
    message: "Blog",
    description: "Text for Blog",
  },
  search: {
    message: "Search",
    description: "Placeholder text for Search box",
  },
  sponsor_title: {
    message: "Sponsor:Squarespace",
    description: "Title of link to Pagecloud sponsor",
  },
  link_about: {
    message: "About",
    description: "Title of link to open the About dialogue",
  },
  placeholder_search_cities: {
    message: "Search cities",
    description: "Placeholder text in the city weather search input",
  },
  weather_search_prompt: {
    message:
      "View the weather and local time of a city by using the search field above.",
    description: "Prompt to search shown when no weather locations are saved",
  },
  open_weather_preferences: {
    message: "Add new city",
    description: "Text for button to open weather preferences",
  },
  close_weather_preferences: {
    message: "Done",
    description: "Text for button to close weather preferences",
  },
  did_you_mean: {
    message: "Did you mean:",
    description: "Text to indicate other suggestions",
  },
  location_not_found: {
    message: "Sorry, we couldn't find that location.",
    description: "Displayed when a searched location returned no results",
  },
  configure_location_prompt: {
    message: "Configure time and temperature settings:",
    description:
      "Prompt to configure the currently-previewing city before adding",
  },
  add_location_text: {
    message: "Add to my cities",
    description:
      "Button to add currently-searched city to list of saved locations",
  },
  existing_city_message: {
    message: "You've already added this city!",
    description:
      "Displayed when a user attempts to add a city already in their list of cities.",
  },
  at_location_limit: {
    message:
      "You’ve reached the 4 location limit. Remove a city before adding another one.",
    description:
      "Warning to indicate the user has added the maximum allowed amount of locations",
  },
  donation_header: {
    message: "Hello fellow UseDelightians :)",
    description: "Header text of donation popup",
  },
  donation_text_1: {
    message:
      "I'm Mike, creative behind UseDelight and my apologies if this pop-up is annoying. If UseDelight brings a little joy into your life, please consider becoming a monthly, or one-time supporter, between a cup of tea and lunch.",
    description:
      "First paragraph of Donation note to be displayed on donation popup",
  },
  donation_text_2: {
    message:
      "For over half a decade,UseDelight has remained free. It takes time to locate awe inspiring videos and edit them as well as money to host and keep them loading fast 24/7 around the globe.",
    description:
      "Second paragraph of Donation note to be displayed on donation popup",
  },
  donation_text_3: {
    message:
      "With your support, we can keep UseDelight free for the masses. If you can, every little bit helps.",
    description:
      "Third paragraph of Donation note to be displayed on donation popup",
  },
  donation_text_4: {
    message: "I am forever grateful that you choose UseDelight!",
    description:
      "Fourth paragraph of Donation note to be displayed on donation popup",
  },
  donate_button: {
    message: "Make a Donation via Paypal",
    description: "Donation button text",
  },
  link_promo: {
    message: "Exclusive Promo!",
    description: "Promo link text on Settings menu",
  },
  link_donate: {
    message: "Donate",
    description: "Donation link text on Settings menu",
  },
  poll_text: {
    message: "Would you like to see more travel deals here?",
    description: "Question for the promotion poll",
  },
  poll_yes: {
    message: "Yes, I like to save money",
    description: "Text in the YES button in the promotion poll",
  },
  poll_no: {
    message: "No, thank you, I guess I will pay full price",
    description: "Text in the NO button in the promotion poll",
  },
  demo_header: {
    message: "Let's watch a quick tutorial",
    description: "Header text of demo popup",
  },
  demo_button: {
    message: "Play The Video",
    description: "Demo play video button text",
  },
  popularlinks_header: {
    message: "Below are popular useful links",
    description: "Header text of popular links popup",
  },
  popularlinks_icon: {
    message: "Popular Links",
    description: "Text of the popular links icon.",
  },
  popular: {
    message: "Popular",
    description: "Text of the popular icon.",
  },
  shopping: {
    message: "Shopping",
    description: "Text of the shopping icon.",
  },
  travel: { message: "Travel", description: "Text of the travel icon." },
  games: { message: "Games", description: "Text of the games icon." },
  games_charity: {
    message: "Games for Charity",
    description: "Text of the Games for Charity icon.",
  },
  other_games: {
    message: "Other Games",
    description: "Text of the Other Games icon.",
  },
  donate: { message: "Donate", description: "Text of the donate icon." },
  all: { message: "All", description: "Text of the 'All' icon." },
  general: {
    message: "General",
    description: "Text for the General settings",
  },
  general_desc: {
    message: "Customize your experience",
    description: "Text for the General settings description",
  },
  video: {
    message: "Video",
    description: "Text for Video",
  },
  video_desc: {
    message: "Customizations for video background",
    description: "Text for Video settings description",
  },
  show_only_favorites: {
    message: "Show only favorite videos",
    description: "Text for show only favorite videos setting",
  },
  multiple_videos: {
    message: "Multiple videos per session",
    description: "Text for multiple videos per session setting",
  },
  multiple_video_headsup: {
    message: "Heads up! This option requires fast Internet connection.",
    description: "Text for multiple videos warning",
  },
  number_videos: {
    message: "Number of videos to use",
    description: "Text for number of videos to use",
  },
  sign_in: {
    message: "Sign-in",
    description: "Text for Sign-in",
  },
  sign_up: {
    message: "Sign-up",
    description: "Text for Sign-up",
  },
  sign_out: {
    message: "Sign-out",
    description: "Text for Sign-out",
  },
  profile: {
    message: "Profile",
    description: "Text for Profile",
  },
  user_profile: {
    message: "User Profile",
    description: "Text for User Profile",
  },
  profile_desc: {
    message: "You are currently logged in.",
    description: "Text for User Profile Description",
  },
  sign_up_desc: {
    message: "Sync your account by signing up.",
    description: "Text for Sign-up description",
  },
  sign_in_desc: {
    message: "Sync your account by signing in.",
    description: "Text for Sign-in description",
  },
  email: {
    message: "Email address",
    description: "Text for E-mail address",
  },
  password: {
    message: "Password",
    description: "Text for Password",
  },
  confirm_password: {
    message: "Confirm Password",
    description: "Text for Confirm Password",
  },
  display_name: {
    message: "Display Name",
    description: "Text for Display Name",
  },
  good_morning: {
    message: "Good morning",
    description: "Text for Good Morning",
  },
  good_afternoon: {
    message: "Good afternoon",
    description: "Text for Good Afternoon",
  },
  good_evening: {
    message: "Good evening",
    description: "Text for Good Evening",
  },
  toggle_greeting: {
    message: "Greeting",
    description: "Text for Greeting",
  },
  sticky_greeting: {
    message: "Sticky Greeting",
    description: "Text for Sticky Greeting",
  },
  rateus_hello: {
    message: "Hello Friend",
    description: "Text for Hello Friend in Rate Us popup",
  },
  rateus_line1: {
    message:
      "UseDelight is a personal dashboard designed to bring you joy and efficiency.",
    description: "Text for line 1 message in Rate Us popup",
  },
  rateus_line2: {
    message: "We work hard to update weekly and add new features.",
    description: "Text for line 2 message in Rate Us popup",
  },
  rateus_line3: {
    message:
      "Your kind words can bring us joy behind the scenes. Please take a few seconds to let us know how we are doing.",
    description: "Text for line 3 message in Rate Us popup",
  },
  rateus_have_suggestion: {
    message: "I have a suggestion",
    description: "Text for I have a suggestion button in Rate Us popup",
  },
  rateus_great_job: {
    message: "Great job - Thank you!",
    description: "Text for Great job thank you button in Rate Us popup",
  },
  rateus_suggest_message: {
    message:
      "Please let us know how we can improve our performance as we respond promptly and take your feedback very seriously.",
    description: "Text for Suggestion message in Rate Us popup",
  },
  rateus_comments_placeholder: {
    message: "Comments/Suggestions",
    description: "Text for Suggestion placeholder in Rate Us popup",
  },
  rateus_name_placeholder: {
    message: "Name (optional)",
    description: "Text for name placeholder in Rate Us popup",
  },
  error_message: {
    message: "Something went wrong. Please try again.",
    description: "Text for generic error message",
  },
  news: {
    message: "News",
    description: "Text for News",
  },
  submit: {
    message: "Submit",
    description: "Text for Submit",
  },
  cancel: {
    message: "Cancel",
    description: "Text for Cancel",
  },
  toggle_quotes: {
    message: "Quotes",
    description: "Visibility toggle for quotes",
  },
  toggle_active_on_open: {
    message: "Active on open",
    description: "Visibility toggle for Active on Open",
  },
};
