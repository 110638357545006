import React, { useEffect, useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Button, Card, CardDeck, Form, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import FormContainer from "../components/FormContainer";
import CardSection from "./CardSection";
import { BASE_API_URL } from "../constants/commonConstants";
import Message from "../components/Message";
import "./CheckoutScreen.css";
import { getPlanInfo, getPrices } from "../actions/subscriptionActions";
import Loader from "../components/Loader";
import CardPrice from "../components/CardPrice";
import { appStorageRemove } from "../components/Utils/Utils";

const CheckoutScreen = ({ history }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const pricesState = useSelector((state) => state.pricesState);
  const createCustomerInfo = useSelector((state) => state.createCustomerInfo);
  const planInfoState = useSelector((state) => state.planState);
  const { planInfo } = planInfoState;
  const { userInfo } = userLogin;
  const { customer } = createCustomerInfo;
  const { prices } = pricesState;

  const [customerId, setCustomerId] = useState(null);
  const [priceId, setPriceId] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [subscribeError, setSubscribeError] = useState("");
  const [subscribing, setSubscribing] = useState(false);

  useEffect(() => {
    dispatch(getPrices());
  }, [dispatch]);

  useEffect(() => {
    if (customer) {
      setCustomerId(customer.data.id);
    } else {
      history.push("/upgrade");
    }
  }, [customer, history]);

  useEffect(() => {
    if (planInfo && !_.isEmpty(planInfo)) {
      history.push("/account");
    }
  }, [history, planInfo]);

  const handleSelectPlan = (event, plan) => {
    event.preventDefault();
    if (subscribing) {
      return;
    }
    setSelectedPlan(plan);
    setPriceId(plan.id);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubscribeError("");
    setSubscribing(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // If a previous payment was attempted, get the latest invoice
    const latestInvoicePaymentIntentStatus = localStorage.getItem(
      "latestInvoicePaymentIntentStatus"
    );

    const createPaymentMethodResult = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });
    const { error, paymentMethod } = createPaymentMethodResult;

    if (error) {
      console.log("[createPaymentMethod error]", error);
      setSubscribing(false);
      setSubscribeError(error.message);
      return;
    }

    const paymentMethodId = paymentMethod.id;
    if (latestInvoicePaymentIntentStatus === "requires_payment_method") {
      // Update the payment method and retry invoice payment
      const invoiceId = localStorage.getItem("latestInvoiceId");
      retryInvoiceWithNewPaymentMethod(
        customerId,
        paymentMethodId,
        invoiceId,
        priceId
      );
      return;
    }

    // Create the subscription
    createSubscription(customerId, paymentMethodId, priceId);
  };

  const createSubscription = (customerId, paymentMethodId, priceId) => {
    const authorization =
      userInfo && userInfo.token ? `Bearer ${userInfo.token}` : "";

    return (
      fetch(`${BASE_API_URL}/subscriptions/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authorization,
        },
        body: JSON.stringify({
          customerId: customerId,
          paymentMethodId: paymentMethodId,
          priceId: priceId,
        }),
      })
        .then((response) => response.json())
        // If the card is declined, display an error to the user.
        .then((result) => {
          console.log("createSubscription-result", result);
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((result) => {
          return {
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            subscription: result,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail, you
        // get a requires_payment_method error.
        .then(handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          console.log("createSubscription-catch-error", error);
          setSubscribing(false);
          setSubscribeError(
            error && error.error ? error.error.message : error.message
          );
        })
    );
  };

  const onSubscriptionComplete = (result) => {
    console.log("onSubscriptionComplete-result", result);
    // Payment was successful. Provision access to your service.
    // Remove invoice from localstorage because payment is now complete.
    // clearCache();
    let subscription = result.subscription;
    if (result && !subscription && result.invoice) {
      subscription = { id: result.invoice.subscription };
      result.subscription = subscription;
      appStorageRemove("customer");
      appStorageRemove("latestInvoiceId");
      appStorageRemove("latestInvoicePaymentIntentStatus");
    }

    dispatch(getPlanInfo(userInfo));

    // Change your UI to show a success message to your customer.
    // onSubscriptionSampleDemoComplete(result);
    // Call your backend to grant access to your service based on
    // the product your customer subscribed to.
    // Get the product by using result.subscription.price.product
  };

  const handlePaymentThatRequiresCustomerAction = ({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
  }) => {
    console.log("handlePaymentThatRequiresCustomerAction", subscription);
    if (subscription && subscription.status === "active") {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    const paymentIntent =
      subscription.planNickName === "Lifetime"
        ? {
            status: subscription.status,
            client_secret: subscription.client_secret,
          }
        : invoice
        ? invoice.payment_intent
        : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === "requires_action" ||
      (isRetry === true && paymentIntent.status === "requires_payment_method")
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            // start code flow to handle updating the payment details
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            throw result;
          } else {
            if (result.paymentIntent.status === "succeeded") {
              // There's a risk of the customer closing the window before callback
              // execution. To handle this case, set up a webhook endpoint and
              // listen to invoice.payment_succeeded. This webhook endpoint
              // returns an Invoice.
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId,
              };
            }
          }
        });
    } else {
      // No customer action needed
      return { subscription, priceId, paymentMethodId };
    }
  };

  const handleRequiresPaymentMethod = ({
    subscription,
    paymentMethodId,
    priceId,
  }) => {
    if (subscription.status === "active") {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    } else if (
      subscription.status === "requires_payment_method" ||
      subscription.latest_invoice?.payment_intent.status ===
        "requires_payment_method"
    ) {
      // Using localStorage to store the state of the retry here
      // (feel free to replace with what you prefer)
      // Store the latest invoice ID and status
      localStorage.setItem("latestInvoiceId", subscription.latest_invoice?.id);
      localStorage.setItem(
        "latestInvoicePaymentIntentStatus",
        subscription.latest_invoice?.payment_intent.status
      );
      throw new Error("Your card was declined.");
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  };

  const retryInvoiceWithNewPaymentMethod = (
    customerId,
    paymentMethodId,
    invoiceId,
    priceId
  ) => {
    const authorization =
      userInfo && userInfo.token ? `Bearer ${userInfo.token}` : "";
    return (
      fetch(`${BASE_API_URL}/subscriptions/retry-invoice`, {
        method: "post",
        headers: {
          "Content-type": "application/json",
          Authorization: authorization,
        },
        body: JSON.stringify({
          customerId: customerId,
          paymentMethodId: paymentMethodId,
          invoiceId: invoiceId,
        }),
      })
        .then((response) => {
          return response.json();
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            invoice: result,
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            isRetry: true,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          console.log("retryInvoiceWithNewPaymentMethod-error", error);
          setSubscribing(false);
          setSubscribeError(
            error && error.error ? error.error.message : error.message
          );
        })
    );
  };

  return (
    <FormContainer>
      {pricesState.error && (
        <Message variant="danger">{pricesState.error}</Message>
      )}
      {pricesState.loading && <Loader />}
      <CardDeck className="mb-2">
        {prices &&
          prices.map((p) => {
            return (
              <CardPrice
                key={p.key}
                price={p}
                selected={priceId === p.id}
                subscribing={subscribing}
                handleSelectPlan={handleSelectPlan}
                extra="37% Savings vs Monthly!"
              />
            );
          })}
      </CardDeck>
      {selectedPlan && (
        <div>
          <Card className="mb-2">
            <Card.Body>
              <h5>Please enter your card details.</h5>
              <p className="mb-0">
                <i className="far fa-arrow-alt-circle-right"></i> Total due now
                ${selectedPlan.price}
              </p>
              <p className="mb-0">
                <i className="far fa-arrow-alt-circle-right"></i>
                <span> Subscribing to {selectedPlan.name} </span>
              </p>
            </Card.Body>
          </Card>
          {subscribeError && (
            <Message variant="danger">{subscribeError}</Message>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="creditCard">
              <Form.Label>CARD</Form.Label>
              <CardSection />
              <a href="https://stripe.com" target="stripe">
                <Image
                  className="mt-1 w-25 float-right"
                  src="../assets/icons/Powered by Stripe - black.png"
                  rounded
                />
              </a>
            </Form.Group>
            <Button
              disabled={!stripe || subscribing || pricesState.loading}
              type="submit"
            >
              {subscribing ? "Subscribing..." : "Subscribe"}
            </Button>
          </Form>
        </div>
      )}
      {subscribing && <Loader />}
    </FormContainer>
  );
};

export default CheckoutScreen;
