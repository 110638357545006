import React from "react";
import { Badge } from "react-bootstrap";

const FeatureRow = ({ text, isFree, isLifetime }) => {
  return (
    <tr>
      <td>{text}</td>
      <td className="text-center">
        <h5>
          {isFree ? (
            <Badge variant="primary">
              <i className="fas fa-check"></i>
            </Badge>
          ) : (
            <Badge variant="secondary">—</Badge>
          )}
        </h5>
      </td>
      <td className="text-center table-success">
        <h5>
          {isLifetime ? (
            <Badge variant="secondary">—</Badge>
          ) : (
            <Badge variant="light">
              <i className="fas fa-check"></i>
            </Badge>
          )}
        </h5>
      </td>
      <td className="text-center table-warning">
        <h5>
          <Badge variant="light">
            <i className="fas fa-check"></i>
          </Badge>
        </h5>
      </td>
    </tr>
  );
};

export default FeatureRow;
