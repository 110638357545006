import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from '@redux-devtools/extension';

import {
  getPlanInfoReducer,
  setPlanInfoReducer,
  userForgotPasswordReducer,
  userLoginReducer,
  userRegisterReducer,
  userResetPasswordReducer,
} from "./reducers/userReducers";
import {
	cancelSubscriptionReducer,
	createCustomerReducer, customerPortalReducer,
	pricesReducer
} from './reducers/subscriptionReducers';
import { appStorageGet } from "./components/Utils/Utils";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userForgotPassword: userForgotPasswordReducer,
  userResetPassword: userResetPasswordReducer,
  createCustomerInfo: createCustomerReducer,
  cancelSubscriptionState: cancelSubscriptionReducer,
  pricesState: pricesReducer,
  planState: setPlanInfoReducer,
  planInfoState: getPlanInfoReducer,
	customerPortalState: customerPortalReducer
});

const userInfo = appStorageGet("userInfo");
const customer = appStorageGet("customer");
const planInfo = appStorageGet("planInfo");

const initialState = {
  userLogin: {
    userInfo,
  },
  createCustomerInfo: {
    customer,
  },
  planState: {
    planInfo,
  },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
