import {
	CANCEL_SUBSCRIPTION_FAIL,
	CANCEL_SUBSCRIPTION_REQUEST,
	CANCEL_SUBSCRIPTION_SUCCESS,
	GET_CUSTOMER_PORTAL_SESSION_FAIL,
	GET_CUSTOMER_PORTAL_SESSION_REQUEST,
	GET_CUSTOMER_PORTAL_SESSION_SUCCESS,
	PRICES_FAIL,
	PRICES_REQUEST,
	PRICES_SUCCESS,
	SUBS_CREATE_CUSTOMER_FAIL,
	SUBS_CREATE_CUSTOMER_REQUEST,
	SUBS_CREATE_CUSTOMER_SUCCESS
} from '../constants/subscriptionConstants';

export const createCustomerReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBS_CREATE_CUSTOMER_REQUEST:
      return { loading: true };
    case SUBS_CREATE_CUSTOMER_SUCCESS:
      return { loading: false, customer: action.payload };
    case SUBS_CREATE_CUSTOMER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const cancelSubscriptionReducer = (state = {}, action) => {
  switch (action.type) {
    case CANCEL_SUBSCRIPTION_REQUEST:
      return { loading: true };
    case CANCEL_SUBSCRIPTION_SUCCESS:
      return { loading: false, cancelledSub: action.payload };
    case CANCEL_SUBSCRIPTION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const pricesReducer = (state = [], action) => {
  switch (action.type) {
    case PRICES_REQUEST:
      return { loading: true };
    case PRICES_SUCCESS:
      return { loading: false, prices: action.payload };
    case PRICES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const customerPortalReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_CUSTOMER_PORTAL_SESSION_REQUEST:
			return { loading: true };
		case GET_CUSTOMER_PORTAL_SESSION_SUCCESS:
			return { loading: false, url: action.payload.url };
		case GET_CUSTOMER_PORTAL_SESSION_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
}
