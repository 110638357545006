import axios from "axios";
import { appStorageRemove, appStorageSet } from "../components/Utils/Utils";
import { BASE_API_URL } from "../constants/commonConstants";
import {
  GET_PLANINFO_SUCCESS,
  SUBS_CREATE_CUSTOMER_SUCCESS,
} from "../constants/subscriptionConstants";
import {
  SET_PLAN_INFO,
  USER_FORGOT_PASSWORD_FAIL,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_FORGOT_PASSWORD_RESET,
  USER_FORGOT_PASSWORD_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_RESET,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_RESET,
  USER_REGISTER_SUCCESS,
  USER_RESET_PASSWORD_FAIL,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_RESET,
  USER_RESET_PASSWORD_SUCCESS,
} from "../constants/userConstants";

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${BASE_API_URL}/auth/login`,
      { email, password },
      config
    );

    if (data.success === true) {
      const payload = {
        name: data.data.name,
        email: data.data.email,
        customerId: data.data.customerId,
        subscription: data.data.subscription,
        stripeSubscription: data.data.stripeSubscription,
        token: data.token,
      };
      dispatch({ type: USER_LOGIN_SUCCESS, payload: payload });
      dispatch(resetPasswordReset());
      appStorageSet({
        key: "userInfo",
        value: payload,
      });

      if (data.data.subscription) {
        dispatch({
          type: SET_PLAN_INFO,
          payload: data.data.subscription,
        });

        appStorageSet({
          key: "planInfo",
          value: data.data.subscription,
        });
      }
    } else {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: data.error,
      });
    }
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};

export const loginReset = () => {
  return {
    type: USER_LOGIN_RESET,
  };
};

export const logout = () => (dispatch) => {
  appStorageRemove("userInfo");
  appStorageRemove("customer");
  appStorageRemove("planInfo");
  appStorageRemove("latestInvoiceId");
  appStorageRemove("latestInvoicePaymentIntentStatus");
  dispatch({ type: USER_LOGOUT, payload: { loggedOut: true } });
  dispatch({ type: SET_PLAN_INFO, payload: null });
  dispatch({ type: SUBS_CREATE_CUSTOMER_SUCCESS, payload: null });
  dispatch({ type: GET_PLANINFO_SUCCESS, payload: null });
  dispatch({ type: USER_REGISTER_SUCCESS, payload: null });
};

export const register = (name, email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${BASE_API_URL}/auth/register`,
      { name, email, password },
      config
    );

    if (data.success === true) {
      const payload = {
        name: data.data.name,
        email: data.data.email,
        customerId: data.data.customerId,
        token: data.token,
      };

      dispatch({ type: USER_REGISTER_SUCCESS, payload: payload });
      dispatch({ type: USER_LOGIN_SUCCESS, payload: payload });

      appStorageSet({
        key: "userInfo",
        value: payload,
      });
    } else {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload: data.result.error,
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};

export const registerReset = () => {
  return {
    type: USER_REGISTER_RESET,
  };
};

export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({
      type: USER_FORGOT_PASSWORD_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${BASE_API_URL}/auth/forgotpassword`,
      { email },
      config
    );

    if (data.success === true) {
      dispatch({
        type: USER_FORGOT_PASSWORD_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({
        type: USER_FORGOT_PASSWORD_FAIL,
        payload: data.result.error,
      });
    }
  } catch (error) {
    dispatch({
      type: USER_FORGOT_PASSWORD_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};

export const forgotPasswordReset = () => {
  return {
    type: USER_FORGOT_PASSWORD_RESET,
  };
};

export const resetPassword = (resetToken, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_RESET_PASSWORD_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.put(
      `${BASE_API_URL}/auth/resetpassword/${resetToken}`,
      { password },
      config
    );

    if (data.success === true) {
      dispatch({
        type: USER_RESET_PASSWORD_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({
        type: USER_RESET_PASSWORD_FAIL,
        payload: data.result.error,
      });
    }
  } catch (error) {
    dispatch({
      type: USER_RESET_PASSWORD_FAIL,
      payload:
        error.response && error.response.data
          ? error.response.data.error
          : error.message,
    });
  }
};

export const resetPasswordReset = () => {
  return {
    type: USER_RESET_PASSWORD_RESET,
  };
};

export const setPlanInfo = (plan) => async (dispatch) => {
  appStorageSet({
    key: "planInfo",
    value: plan,
  });
  dispatch({
    type: SET_PLAN_INFO,
    payload: plan,
  });
};
