export const zhCnMessages = {
  app_name: {
    message: "Chrome Theme Infinity New Tab Backgrounds HD",
    description: "The title of the application,displayed in the web store",
  },
  app_short_name: {
    message: "UseDelight",
    description:
      "The short title of the application,displayed in the web store and from the app launcher",
  },
  app_description: {
    message: "免费视频壁纸令人难以置信的起始页，天气，搜索引擎，新闻，游戏",
    description:
      "The description of the application,displayed in the web store",
  },
  new_tab: {
    message: "新标签",
    description: "Title of the New Tab page",
  },
  no_connection: {
    message: "没有网络连接",
    description: "Alerts the user that they have no connection to the internet",
  },
  battery_mode: {
    message: "省电器",
    description: "Alerts the user that they are seeing the battery mode",
  },
  loading_videos: {
    message: "正在加载视频...",
    description: "Alerts the user that videos are being loaded",
  },
  link_apps: {
    message: "应用",
    description: "Title of link to open the Apps page",
  },
  link_history: {
    message: "历史",
    description: "Title of link to open the History page",
  },
  link_bookmarks: {
    message: "书签",
    description: "Title of link to open the Bookmarks page",
  },
  title_settings: {
    message: "组态",
    description: "Title of the settings dropdown",
  },
  toggle_topsites: {
    message: "访问最多的站点",
    description: "Visibility toggle for top sites",
  },
  toggle_logo: {
    message: "UseDelight徽标",
    description: "Visibility toggle for the UseDelight logo",
  },
  toggle_launchers: {
    message: "发射器",
    description: "Visibility toggle for Chrome launcher links",
  },
  toggle_weather: {
    message: "天气和时间",
    description: "Visibility toggle for the weather and time widget",
  },
  toggle_credits: {
    message: "视频功劳",
    description: "Visibility toggle for the video's credit information",
  },
  toggle_video: {
    message: "播放视频",
    description: "Play toggle for the video",
  },
  toggle_demo: {
    message: "显示演示",
    description: "Show the demo popup",
  },
  toggle_poplinks: {
    message: "热门链接",
    description: "Show the Popular Links",
  },
  toggle_search: {
    message: "搜索",
    description: "Show the Search Box",
  },
  link_review: {
    message: "评价我们",
    description: "Title of link to review UseDelight",
  },
  add_to_chrome: {
    message: "新增至Chrome",
    description: "Link to UseDelight in Chrome Store",
  },
  about_us: {
    message: "关于UseDelight",
    description: "Link to UseDelight About Us Page",
  },
  add_to_your_browser: {
    message: "添加到浏览器",
    description: "Button text for Add to your browser",
  },
  add_to_browser: {
    message: "添加到浏览器",
    description: "Button text for Add to browser",
  },
  available_for: {
    message: "可以用来: ",
    description: "Text for Available for",
  },
  available_in_chrome: {
    message: "在Chrome中可用",
    description: "Text for Available in Chrome",
  },
  available_in_firefox: {
    message: "在Firefox中可用",
    description: "Text for Available in Firefox",
  },
  footer_about_header: {
    message: "UseDelight-大自然壁纸高清视频背景",
    description: "Header text in the About footer",
  },
  footer_about_text: {
    message:
      "打开浏览器的新标签，以查看1500多种高清视频背景之一，其中的风景范围包括海洋，冬季风景，森林以及介于两者之间的所有事物。",
    description: "Text in the About footer",
  },
  our_policies: {
    message: "我们的政策",
    description: "Text for Our Policies",
  },
  terms_of_use: {
    message: "使用条款",
    description: "Text for Terms of Use",
  },
  privacy_policy: {
    message: "隐私政策",
    description: "Text for Privacy Policy",
  },
  cookies_policy: {
    message: "Cookies政策",
    description: "Text for Cookies Policy",
  },
  contact: {
    message: "联系",
    description: "Text for Contact",
  },
  blog: {
    message: "博客",
    description: "Text for Blog",
  },
  search: {
    message: "搜索",
    description: "Placeholder text for Search box",
  },
  sponsor_title: {
    message: "Sponsor:Squarespace",
    description: "Title of link to Pagecloud sponsor",
  },
  link_about: {
    message: "更多信息",
    description: "Title of link to open the About dialogue",
  },
  placeholder_search_cities: {
    message: "搜索城市",
    description: "Placeholder text in the city weather search input",
  },
  weather_search_prompt: {
    message: "使用上面的搜索字段查看城市的天气和当地时间。",
    description: "Prompt to search shown when no weather locations are saved",
  },
  open_weather_preferences: {
    message: "新增城市",
    description: "Text for button to open weather preferences",
  },
  close_weather_preferences: {
    message: "完成了",
    description: "Text for button to close weather preferences",
  },
  did_you_mean: {
    message: "你的意思是：",
    description: "Text to indicate other suggestions",
  },
  location_not_found: {
    message: "抱歉，我们找不到该位置。",
    description: "Displayed when a searched location returned no results",
  },
  configure_location_prompt: {
    message: "配置时间和温度设置：",
    description:
      "Prompt to configure the currently-previewing city before adding",
  },
  add_location_text: {
    message: "添加到我的城市",
    description:
      "Button to add currently-searched city to list of saved locations",
  },
  existing_city_message: {
    message: "您已经添加了这个城市！",
    description:
      "Displayed when a user attempts to add a city already in their list of cities.",
  },
  at_location_limit: {
    message: "您已达到4个位置的限制。 在添加另一个城市之前，先删除一个城市。",
    description:
      "Warning to indicate the user has added the maximum allowed amount of locations",
  },
  donation_header: {
    message: "大家好UseDelightians :)",
    description: "Header text of donation popup",
  },
  donation_text_1: {
    message:
      "我是Mike，是UseDelight的创作者，如果此弹出窗口令人讨厌，我深表歉意。 如果UseDelight给您的生活带来一点快乐，请考虑在喝茶和午餐之间成为每月或一次性的支持者。",
    description:
      "First paragraph of Donation note to be displayed on donation popup",
  },
  donation_text_2: {
    message:
      "在过去的五年中，UseDelight一直免费。 找到令人敬畏的视频并进行编辑和花费大量时间来托管和保持它们在全球范围内24/7的快速加载需要花费时间。",
    description:
      "Second paragraph of Donation note to be displayed on donation popup",
  },
  donation_text_3: {
    message:
      "在您的支持下，我们可以让大众免费使用UseDelight。 如果可以的话，一点点帮助。",
    description:
      "Third paragraph of Donation note to be displayed on donation popup",
  },
  donation_text_4: {
    message: "感谢您选择UseDelight！",
    description:
      "Fourth paragraph of Donation note to be displayed on donation popup",
  },
  donate_button: {
    message: "通过贝宝捐款",
    description: "Donation button text",
  },
  link_promo: {
    message: "独家促销！",
    description: "Promo link text on Settings menu",
  },
  link_donate: {
    message: "捐",
    description: "Donation link text on Settings menu",
  },
  poll_text: {
    message: "您想在这里看到更多旅行优惠吗？",
    description: "Question for the promotion poll",
  },
  poll_yes: {
    message: "是的，我喜欢省钱",
    description: "Text in the YES button in the promotion poll",
  },
  poll_no: {
    message: "不，谢谢，我想我将全额支付",
    description: "Text in the NO button in the promotion poll",
  },
  demo_header: {
    message: "让我们看一个快速的教程",
    description: "Header text of demo popup",
  },
  demo_button: {
    message: "播放视频",
    description: "Demo play video button text",
  },
  popularlinks_header: {
    message: "以下是热门的有用链接",
    description: "Header text of popular links popup",
  },
  popularlinks_icon: {
    message: "热门链接",
    description: "Text of the popular links icon.",
  },
  popular: {
    message: "流行",
    description: "Text of the popular icon.",
  },
  shopping: {
    message: "购物",
    description: "Text of the shopping icon.",
  },
  travel: { message: "旅行", description: "Text of the travel icon." },
  games: { message: "游戏类", description: "Text of the games icon." },
  games_charity: {
    message: "慈善游戏",
    description: "Text of the Games for Charity icon.",
  },
  other_games: {
    message: "其他游戏",
    description: "Text of the Other Games icon.",
  },
  donate: { message: "捐", description: "Text of the donate icon." },
  all: { message: "所有", description: "Text of the 'All' icon." },
  general: {
    message: "一般",
    description: "Text for the General settings",
  },
  general_desc: {
    message: "定制您的体验",
    description: "Text for the General settings description",
  },
  video: {
    message: "视频",
    description: "Text for Video",
  },
  video_desc: {
    message: "视频背景的自定义",
    description: "Text for Video settings description",
  },
  show_only_favorites: {
    message: "仅显示喜欢的视频",
    description: "Text for show only favorite videos setting",
  },
  multiple_videos: {
    message: "每个会话多个视频",
    description: "Text for multiple videos per session setting",
  },
  multiple_video_headsup: {
    message: "小心！ 此选项需要快速的Internet连接。",
    description: "Text for multiple videos warning",
  },
  number_videos: {
    message: "要使用的视频数",
    description: "Text for number of videos to use",
  },
  sign_in: {
    message: "登入",
    description: "Text for Sign-in",
  },
  sign_up: {
    message: "寄存器",
    description: "Text for Sign-up",
  },
  sign_out: {
    message: "登出",
    description: "Text for Sign-out",
  },
  profile: {
    message: "个人资料",
    description: "Text for Profile",
  },
  user_profile: {
    message: "用户资料",
    description: "Text for User Profile",
  },
  profile_desc: {
    message: "您目前已登录。",
    description: "Text for User Profile Description",
  },
  sign_up_desc: {
    message: "通过注册来同步您的帐户。",
    description: "Text for Sign-up description",
  },
  sign_in_desc: {
    message: "通过登录来同步您的帐户。",
    description: "Text for Sign-in description",
  },
  email: {
    message: "电子邮件地址",
    description: "Text for E-mail address",
  },
  password: {
    message: "密码",
    description: "Text for Password",
  },
  confirm_password: {
    message: "确认密码",
    description: "Text for Confirm Password",
  },
  display_name: {
    message: "显示名称",
    description: "Text for Display Name",
  },
  good_morning: {
    message: "早上好",
    description: "Text for Good Morning",
  },
  good_afternoon: {
    message: "下午好",
    description: "Text for Good Afternoon",
  },
  good_evening: {
    message: "晚上好",
    description: "Text for Good Evening",
  },
  toggle_greeting: {
    message: "问候语",
    description: "Text for Greeting",
  },
  sticky_greeting: {
    message: "留下问候",
    description: "Text for Sticky Greeting",
  },
  rateus_hello: {
    message: "你好朋友",
    description: "Text for Hello Friend in Rate Us popup",
  },
  rateus_line1: {
    message: "UseDelight是一个个人仪表板，旨在为您带来欢乐和效率。",
    description: "Text for line 1 message in Rate Us popup",
  },
  rateus_line2: {
    message: "我们努力每周更新并添加新功能。",
    description: "Text for line 2 message in Rate Us popup",
  },
  rateus_line3: {
    message:
      "您的客气话可以带给我们幕后的欢乐。 请花几秒钟的时间告诉我们我们的情况。",
    description: "Text for line 3 message in Rate Us popup",
  },
  rateus_have_suggestion: {
    message: "我有个建议",
    description: "Text for I have a suggestion button in Rate Us popup",
  },
  rateus_great_job: {
    message: "干得好-谢谢！",
    description: "Text for Great job thank you button in Rate Us popup",
  },
  rateus_suggest_message: {
    message:
      "请及时告知我们，并认真对待您的反馈，以让我们知道如何改善我们的绩效。",
    description: "Text for Suggestion message in Rate Us popup",
  },
  rateus_comments_placeholder: {
    message: "评论/建议",
    description: "Text for Suggestion placeholder in Rate Us popup",
  },
  rateus_name_placeholder: {
    message: "名称（可选）",
    description: "Text for name placeholder in Rate Us popup",
  },
  error_message: {
    message: "出了些问题。 请再试一遍。",
    description: "Text for generic error message",
  },
  news: {
    message: "新闻",
    description: "Text for News",
  },
  submit: {
    message: "提交",
    description: "Text for Submit",
  },
  cancel: {
    message: "取消",
    description: "Text for Cancel",
  },
};
