import React from "react";
import { Badge, Button, Card } from "react-bootstrap";

import "./CardPrice.css";

const CardPrice = ({
  price,
  extra,
  selected,
  handleSelectPlan,
  subscribing,
}) => {
  const billedName = `${price.billed
    .charAt(0)
    .toUpperCase()}${price.billed.substring(1)}`;

  return (
    <Card
      className="subscription-plan"
      border={selected ? "success" : "light"}
      onClick={(e) => handleSelectPlan(e, price)}
    >
      <Card.Header>{price.name}</Card.Header>
      <Card.Body className="text-center d-flex flex-column justify-content-between">
        <Card.Title className="text-center">
          <h4>
            {price.type === "one_time" ? (
              <Badge variant="info">{`$${price.price}`}</Badge>
            ) : (
              <Badge variant="info">{`$${price.monthly}/month`}</Badge>
            )}
          </h4>
        </Card.Title>
        <div>
          <p className="text-muted mb-0">{`Billed ${billedName}`}</p>
          {price.type !== "one_time" && <p>{`$${price.yearly}/year`}</p>}
          <p className="text-success">
            {price.yearly === price.price ? extra : <span>&nbsp;</span>}
          </p>
          {price.type === "one_time" && (
            <p className="text-success text-left" style={{ fontSize: "12px" }}>
              - Limited Time Offer
              <br />- Get Exclusive Items
            </p>
          )}
        </div>
        <Card.Text>
          <Button
            className="w-100"
            disabled={subscribing}
            variant={selected ? "success" : "outline-success"}
          >
            {selected ? "Selected" : "Select"}
          </Button>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default CardPrice;
