export const SUBS_CREATE_CUSTOMER_REQUEST = "SUBS_CREATE_CUSTOMER_REQUEST";
export const SUBS_CREATE_CUSTOMER_SUCCESS = "SUBS_CREATE_CUSTOMER_SUCCESS";
export const SUBS_CREATE_CUSTOMER_FAIL = "SUBS_CREATE_CUSTOMER_FAIL";

export const CREATE_SUBSCRIPTION_REQUEST = "CREATE_SUBSCRIPTION_REQUEST";
export const CREATE_SUBSCRIPTION_SUCCESS = "CREATE_SUBSCRIPTION_SUCCESS";
export const CREATE_SUBSCRIPTION_FAIL = "CREATE_SUBSCRIPTION_FAIL";

export const UPDATE_SUBSCRIPTION_REQUEST = "UPDATE_SUBSCRIPTION_REQUEST";
export const UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS";
export const UPDATE_SUBSCRIPTION_FAIL = "UPDATE_SUBSCRIPTION_FAIL";

export const CANCEL_SUBSCRIPTION_REQUEST = "CANCEL_SUBSCRIPTION_REQUEST";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_FAIL = "CANCEL_SUBSCRIPTION_FAIL";

export const PRICES_REQUEST = "PRICES_REQUEST";
export const PRICES_SUCCESS = "PRICES_SUCCESS";
export const PRICES_FAIL = "PRICES_FAIL";

export const GET_PLANINFO_REQUEST = "GET_PLANINFO_REQUEST";
export const GET_PLANINFO_SUCCESS = "GET_PLANINFO_SUCCESS";
export const GET_PLANINFO_FAIL = "GET_PLANINFO_FAIL";

export const GET_CUSTOMER_PORTAL_SESSION_REQUEST = "GET_CUSTOMER_PORTAL_SESSION_REQUEST";
export const GET_CUSTOMER_PORTAL_SESSION_SUCCESS = "GET_CUSTOMER_PORTAL_SESSION_SUCCESS";
export const GET_CUSTOMER_PORTAL_SESSION_FAIL = "GET_CUSTOMER_PORTAL_SESSION_FAIL";
