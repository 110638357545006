export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGIN_RESET = "USER_LOGIN_RESET";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";
export const USER_REGISTER_RESET = "USER_REGISTER_RESET";

export const USER_FORGOT_PASSWORD_REQUEST = "USER_FORGOT_PASSWORD_REQUEST";
export const USER_FORGOT_PASSWORD_SUCCESS = "USER_FORGOT_PASSWORD_SUCCESS";
export const USER_FORGOT_PASSWORD_FAIL = "USER_FORGOT_PASSWORD_FAIL";
export const USER_FORGOT_PASSWORD_RESET = "USER_FORGOT_PASSWORD_RESET";

export const USER_RESET_PASSWORD_REQUEST = "USER_RESET_PASSWORD_REQUEST";
export const USER_RESET_PASSWORD_SUCCESS = "USER_RESET_PASSWORD_SUCCESS";
export const USER_RESET_PASSWORD_FAIL = "USER_RESET_PASSWORD_FAIL";
export const USER_RESET_PASSWORD_RESET = "USER_RESET_PASSWORD_RESET";

export const SET_PLAN_INFO = "SET_PLAN_INFO";
