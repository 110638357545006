import React from "react";

import { BrowserRouter as Router, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import Header from "./components/Header";
import Footer from "./components/Footer";
import HomeScreen from "./screens/HomeScreen";
import AccountScreen from "./screens/AccountScreen";
import UpgradeScreen from "./screens/UpgradeScreen";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import ForgotPasswordScreen from "./screens/ForgotPasswordScreen";
import CheckoutScreen from "./screens/CheckoutScreen";
import PasswordReset from "./screens/PasswordReset";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe("pk_test_bg39s41UpBAV9oKniywQo7T9");
const stripePromise = loadStripe("pk_live_V7jyYaquXIa435LHquBeFkuu");

const App = () => {
  return (
    <Elements stripe={stripePromise}>
      <Router>
        <Header />
        <main>
          <Container>
            <Route path="/" component={HomeScreen} exact />
            <Route path="/login" component={LoginScreen} />
            <Route path="/register" component={RegisterScreen} />
            <Route path="/forgotpassword" component={ForgotPasswordScreen} />
            <Route path="/password-reset" component={PasswordReset} />
            <Route path="/account" component={AccountScreen} />
            <Route path="/upgrade" component={UpgradeScreen} />
            <Route path="/checkout" component={CheckoutScreen} />
          </Container>
        </main>
        <Footer />
      </Router>
    </Elements>
  );
};

export default App;
